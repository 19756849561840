import axios from "axios";
import TransactionNotification from "components/notifications";
import toast from "react-hot-toast";

interface GetLatestTransactionsType {
	apiAddress: string;
	address: string;
	contractAddress: string;
	timeout: number;
	page?: number;
	url?: string;
}

const fetchTransactions = (url: string) =>
	async function getTransactions({ apiAddress, address, contractAddress, timeout }: GetLatestTransactionsType) {
		try {
			const { data } = await axios.get(`${apiAddress}${url}`, {
				params: {
					sender: address,
					receiver: contractAddress,
					condition: "must",
					size: 25,
				},
				timeout,
			});

			return {
				data: data,
				success: data !== undefined,
			};
		} catch (err) {
			return {
				success: false,
			};
		}
	};

export const getTransactions = fetchTransactions("/transactions");
export const getTransactionsCount = fetchTransactions("/transactions/count");

export const notifyTransaction = (transaction: any) => {
	let message = "Transaction pending";
	if (transaction.status === "success") {
		message = "Transaction successful";
	} else if (transaction.status === "fail") {
		message = "Transaction failed";
	} else if (transaction.status === "pending") {
		message = "Transaction pending";
	} else if (transaction.status === "notExecuted") {
		message = "Transaction not executed";
	}
	toast.custom((t) => (
		<TransactionNotification
			{...t}
			message={message}
			txHash={transaction.txHash}
			txValue={transaction.value}
			status={transaction.status}
		/>
	));
};
