import { refreshAccount, transactionServices, useGetAccountInfo, useGetNetworkConfig } from "@elrondnetwork/dapp-core";
import { getTransactions } from "api/transaction";
import { useEffect, useState } from "react";
import { useLocalStorage } from "react-use";
import { StateType, TransactionType } from "types/transactions";

const useTransactions = (contractAddress: string) => {
	const {
		network: { apiAddress },
	} = useGetNetworkConfig();
	const { success, fail, hasActiveTransactions } = transactionServices.useGetActiveTransactionsStatus();

	const [viewedTransactionHashes, setViewedTransactionHashes] = useLocalStorage<string[]>(
		"transaction_viewed_hashes",
		[]
	);
	const [state, setState] = useState<StateType>({
		transactions: [],
		transactionsFetched: undefined,
	});
	const account = useGetAccountInfo();

	const fetchData = () => {
		if (success || fail || !hasActiveTransactions) {
			getTransactions({
				apiAddress,
				address: account.address,
				timeout: 3000,
				contractAddress,
			}).then(({ data, success: transactionsFetched }: any) => {
				refreshAccount();
				setState({
					transactions: data,
					transactionsFetched,
				});
			});
		}
	};

	const markTransactionViewed = (txHash: string) => {
		const arr = viewedTransactionHashes ?? [];
		setViewedTransactionHashes([...arr, txHash]);
	};

	const getUnseenTransactions = () => {
		const unseenTransactions = state.transactions.filter((transaction) => {
			return viewedTransactionHashes ? !viewedTransactionHashes.includes(transaction.txHash) : true;
		});
		return unseenTransactions;
	};

	useEffect(fetchData, [success, fail, hasActiveTransactions]);

	const { transactions } = state;

	return { transactions, markTransactionViewed, getUnseenTransactions };
};

export default useTransactions;
