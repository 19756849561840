import { motionContainerProps, scaleFadeInVariants } from "animation/variants";
import Button from "components/buttons";
import { Icon as LBIcon } from "components/icons/Icon";
import { LKTOKEN_ID1, LKTOKEN_ID2, TOKEN_ID } from "config";
import dayjs from "dayjs";
import { motion } from "framer-motion/dist/framer-motion";
import { ReactNode, useEffect, useMemo } from "react";
import toast from "react-hot-toast";
import useStakeContract from "utils/useStakeContract";

interface ClaimModalProps {
	id: number;
	stakeTypeId: number;
	stakedAmount: string;
	stakeTimestamp: string;
	lockingTimestamp: string;
	claimableAmount: string;
	rewardAmount: string;
	claimable: boolean;
	unstaked: boolean;
	unstakeTimestamp: string;
	delegationTimestamp: string;
	tokenId: string;
	title: string;
	apr: number;
	Icon?: ReactNode;
	handleClaim?: () => void;
	onClose: () => void;
}
const ClaimModal = ({
	id,
	tokenId,
	title,
	Icon,
	stakedAmount,
	stakeTypeId,
	stakeTimestamp,
	lockingTimestamp,
	claimableAmount,
	claimable,
	rewardAmount,
	unstaked,
	unstakeTimestamp,
	delegationTimestamp,
	onClose,
	apr,
}: ClaimModalProps) => {
	const { dateUntil, stakeDate, unlockDate, unstakeDate, claimDate, undelegateDate } = useMemo(() => {
		let dateUntil: string | number = Date.now();
		let startDate: string | number = Date.now();
		let endDate: string | number = Date.now();
		let undelegateDate = parseInt(unstakeTimestamp) + parseInt(delegationTimestamp);
		let stakeDate = parseInt(stakeTimestamp);
		let unlockDate = parseInt(stakeTimestamp) + parseInt(lockingTimestamp);
		let unstakeDate = parseInt(unstakeTimestamp);
		let claimDate = unstakeTimestamp ? parseInt(unstakeTimestamp) + parseInt(delegationTimestamp) : null;

		if (claimable && unstakeTimestamp && delegationTimestamp) {
			startDate = unstakeTimestamp;
			endDate = delegationTimestamp;
		} else if (stakeTimestamp && lockingTimestamp) {
			startDate = stakeTimestamp;
			endDate = lockingTimestamp;
		}

		dateUntil = endDate;

		return {
			startDate,
			endDate,
			dateUntil,
			stakeDate,
			unstakeDate,
			claimDate,
			unlockDate,
			undelegateDate,
		};
	}, [claimable, delegationTimestamp, lockingTimestamp, stakeTimestamp, unstakeTimestamp]);

	const { stakeContract } = useStakeContract(TOKEN_ID);
	const { stakeContract: stakeContractLk1 } = useStakeContract(LKTOKEN_ID1);
	const { stakeContract: stakeContractLk2 } = useStakeContract(LKTOKEN_ID2);

	const handleClick = async () => {
		const contract =
			tokenId === TOKEN_ID ? stakeContract : tokenId === LKTOKEN_ID1 ? stakeContractLk1 : stakeContractLk2;

		if (unstaked) {
			await contract?.createClaimTransaction(id);
			toast("Sending claim transaction.");
		} else {
			await contract?.createUnstakeTransaction(id);
			toast("Sending unstake transaction.");
		}
		onClose();
	};

	return (
		<div className="lboard-modal">
			<motion.div className="lboard-modal__content" {...motionContainerProps} variants={scaleFadeInVariants}>
				<div className="lboard-modal__header">
					{title && <img src={`/assets/images/${title.toLowerCase()}.png`} alt="" />}
					{Icon && Icon}
					<h2>{title}</h2>
				</div>
				<div className="lboard-modal__body">
					<ul>
						<li>
							<span className="lboard-modal__label">
								<LBIcon name="money" primary />
								Staked
							</span>
							<span>{stakedAmount}</span>
						</li>
						<li>
							<span className="lboard-modal__label">
								<LBIcon name="gift" primary />
								Rewards
							</span>
							<span> {(parseFloat(rewardAmount) + parseFloat(rewardAmount) * 0.42).toPrecision(2)} LAND</span>
						</li>
						<li>
							<span className="lboard-modal__label">
								<LBIcon name="calendar_check" primary />
								Stake Time
							</span>
							<span>{dayjs.unix(stakeDate).format("MMM DD, YYYY hh:mm A")}</span>
						</li>
						<li>
							<span className="lboard-modal__label">
								<LBIcon name="calendar_star" primary />
								Unlock Time
							</span>
							<span> {dayjs.unix(unlockDate).format("MMM DD, YYYY hh:mm A")}</span>
						</li>
						{undelegateDate && (
							<li>
								<span className="lboard-modal__label">
									<LBIcon name="calendar_star" primary />
									Delegate Time
								</span>
								<span> {dayjs.unix(undelegateDate).format("MMM DD, YYYY hh:mm A")}</span>
							</li>
						)}
						{unstaked && (
							<li>
								<span className="lboard-modal__label">
									<LBIcon name="calendar_star" primary />
									Unstake Time
								</span>
								<span> {dayjs.unix(unstakeDate).format("MMM DD, YYYY hh:mm A")}</span>
							</li>
						)}
						{unstaked && claimDate && dayjs(claimDate).isValid() && (
							<li>
								<span className="lboard-modal__label">
									<LBIcon name="calendar_star" primary />
									Claim Time
								</span>
								<span> {dayjs.unix(claimDate).format("MMM DD, YYYY hh:mm A")}</span>
							</li>
						)}
						<li>
							<span className="lboard-modal__label">
								<LBIcon name="percentage" primary />
								APR
							</span>
							<span>{apr}%</span>
						</li>
					</ul>
					<motion.div className="home__form--info">
						<LBIcon name="info" primary />
						<span>If you unstake before end period you'll only receive 70% of rewards.</span>
					</motion.div>
					<div className="lboard-modal__actions">
						<Button
							className="filled"
							onClick={handleClick}
							disabled={unstaked && dateUntil > 0}
							hideAnimate
							hideComingSoon>
							{unstaked ? "Claim Rewards" : "Unstake"}
						</Button>
						<Button className="text" onClick={onClose} hideAnimate>
							Cancel
						</Button>
					</div>
				</div>
			</motion.div>
		</div>
	);
};

export default ClaimModal;
