import { addressIsValid, useGetAccountInfo, useGetNetworkConfig } from "@elrondnetwork/dapp-core";
import { fadeInVariants, motionContainerProps } from "animation/variants";
import axios from "axios";
import Button from "components/buttons";
import TokenPicker from "components/buttons/TokenPicker";
import PlanCard from "components/cards";
import CheckboxGroup from "components/checkbox/CheckboxGroup";
import { Icon } from "components/icons/Icon";
import Input from "components/input";
import { landPlans, LAUNCH_TIME, TOKEN_ID } from "config";
import * as dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import { useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useSearchParams } from "react-router-dom";
import { useMedia } from "react-use";
import useStakeContract from "utils/useStakeContract";
import useTimeUntil from "utils/useTimeUntil";

dayjs.extend(duration);

const LabelButton = (props: any) => (
	<button
		type="button"
		className="flex px-2 py-1 text-sm font-bold uppercase rounded bg-purple-darker text-purple"
		{...props}>
		Max
	</button>
);

const lklandOptions = [
	{
		label: "LKLAND-6cf78e",
		value: "LKLAND-6cf78e",
		checked: true,
	},
	{
		label: "LKLAND-c617f7",
		value: "LKLAND-c617f7",
		checked: false,
	},
];

const Home = () => {
	const { address, account, ...rest } = useGetAccountInfo();
	const { network } = useGetNetworkConfig();

	const isMobile = useMedia("(max-width: 768px)");

	const [searchParams] = useSearchParams();

	// const { timeLeft } = useTimeUntil(1648231200000);
	const { timeLeft, days: d, hours, minutes, seconds } = useTimeUntil(LAUNCH_TIME);

	const [stakedLandQuantity, setStakedLandQuantity] = useState("");
	const [referralCode, setReferralCode] = useState("");
	const [selectedToken, setSelectedToken] = useState(TOKEN_ID);
	const [lklandTypeId, setLklandType] = useState<any>("LKLAND-6cf78e");
	const [stakeTypes, setStakeTypes] = useState<any[]>([]);
	const [loaded, setLoaded] = useState({ balance: false, stake_types: false });
	const [totalLandBalance, setTotalLandBalance] = useState(0);
	const [totalLkLandBalance, setTotalLkLandBalance] = useState<any>({
		"LKLAND-6cf78e": 0,
		"LKLAND-c617f7": 0,
	});
	const [activeDay, setActiveDay] = useState(15);

	const { stakeContract } = useStakeContract(selectedToken === TOKEN_ID ? TOKEN_ID : lklandTypeId);

	const handleChangeStakedQuantity = (e: any) => {
		const regex = RegExp(/[0-9]+/g);
		const test_result = regex.test(e.target.value);
		let newStakedQuantity = "";

		if (test_result) {
			const value = parseInt(e.target.value);
			if (value > 0 && value <= 20000) {
				newStakedQuantity = e.target.value;
				setStakedLandQuantity(newStakedQuantity);
			}
		}
		if (e.target.value === "") {
			setStakedLandQuantity("");
		}
	};

	const handleChangeReferralCode = (e: any) => {
		setReferralCode(e.target.value);
	};

	const handleStake = async () => {
		const balance = selectedToken === TOKEN_ID ? totalLandBalance : totalLkLandBalance[lklandTypeId];
		if (parseInt(stakedLandQuantity) <= balance) {
			const tokenId = selectedToken === TOKEN_ID ? TOKEN_ID : lklandTypeId;
			const selectedPlan = plans.filter((plan: any) => plan.days.valueOf() === activeDay)[0];

			let referralAddress = referralCode.length > 0 ? referralCode : undefined;

			if (referralAddress && !addressIsValid(referralAddress)) {
				const res = await fetch(`https://api.elrond.com/usernames/${referralCode.split(".")[0]}`);
				const data = await res.json();
				referralAddress = data.address;
			}

			if (account.address === referralAddress) {
				toast.error("Nice try but you cannot refer yourself");
				return;
			}

			await stakeContract?.createStakeTransaction(
				tokenId,
				selectedPlan.id,
				parseInt(stakedLandQuantity),
				referralAddress
			);
		} else {
			toast.error("Not enough land");
		}
	};

	const handleSwitchToken = (token: string) => setSelectedToken(token);

	useEffect(() => {
		setReferralCode(searchParams.get("referral") ?? "");
	}, [searchParams]);

	useEffect(() => {
		if (account.address != "" && !loaded.balance) {
			setLoaded({ ...loaded, balance: true });
			axios.get(`${network.apiAddress}/accounts/${account.address}/tokens?size=100`).then((res: any) => {
				if (res.data?.length > 0) {
					const tokens = res.data.filter(
						(a: any) =>
							a?.identifier === TOKEN_ID ||
							a?.ticker === TOKEN_ID ||
							a?.ticker === "SVEN-4b35b0" ||
							a?.ticker === "SVEN-0deee5"
					);
					const lkLand1 = res.data.filter(
						(a: any) => a?.identifier === "LKLAND-6cf78e" || a?.ticker === "LKLAND-6cf78e"
					);
					const lkLand2 = res.data.filter(
						(a: any) => a?.identifier === "LKLAND-c617f7" || a?.ticker === "LKLAND-c617f7"
					);
					setTotalLandBalance(tokens.length > 0 ? Math.floor(tokens[0]?.balance / 10 ** 18) : 0);
					setTotalLkLandBalance({
						"LKLAND-6cf78e": lkLand1.length > 0 ? lkLand1[0]?.balance / 10 ** 18 : 0,
						"LKLAND-c617f7": lkLand2.length > 0 ? lkLand2[0]?.balance / 10 ** 18 : 0,
					});
				}
			});
		}
	}, [account]);

	useEffect(() => {
		if (stakeContract) {
			stakeContract.getStakeTypes().then((stakeTypes: any) => {
				setStakeTypes(stakeTypes);
			});
		}
	}, [stakeContract, selectedToken]);

	const plans: any = useMemo(() => {
		return stakeTypes.length > 0
			? stakeTypes
					.map((stakeType) => {
						const stakeDays = dayjs.duration(stakeType.locking_timestamp.valueOf(), "s").asDays();
						const plans = landPlans.filter((landPlan) => stakeDays == landPlan.days);

						if (plans.length > 0) {
							return {
								...plans[0],
								...stakeType,
								id: stakeType.stake_type_id.valueOf(),
								apr: selectedToken === TOKEN_ID ? plans[0].apr : plans[0].apr * 0.25,
							};
						} else return null;
					})
					.filter((plan) => plan != null)
			: landPlans.map((landPlan) => ({
					...landPlan,
					apr: selectedToken === TOKEN_ID ? landPlan.apr : landPlan.apr * 0.25,
			  }));
	}, [selectedToken, stakeTypes]);

	// @ts-ignore
	const balance = selectedToken === TOKEN_ID ? totalLandBalance : totalLkLandBalance[lklandTypeId];
	const disabled = timeLeft > 0 || stakedLandQuantity === "0" || !stakedLandQuantity || !address;

	const handleSelectLkLand = (options: any[]) => {
		setLklandType(options.filter((a: any) => a.checked)[0].value);
	};

	return (
		<motion.div className="home" {...motionContainerProps}>
			<div className="stake-container">
				<div className="home__title">
					<motion.h1 variants={fadeInVariants}>
						EARN - STAKE YOUR <span className="text-purple">{selectedToken}</span>
					</motion.h1>
					<motion.p variants={fadeInVariants}>Staking starts on 26 March 2022.</motion.p>
				</div>
				<TokenPicker token={selectedToken} tokens={[TOKEN_ID, "LKLAND"]} onClick={handleSwitchToken} />
				<motion.div className="home__form" onSubmit={() => {}} {...motionContainerProps}>
					<Input
						placeholder="0"
						label="Amount to Stake"
						value={stakedLandQuantity}
						onChange={handleChangeStakedQuantity}
						LabelButton={
							<LabelButton
								onClick={() =>
									handleChangeStakedQuantity({
										target: {
											value: balance < 10000 ? balance : 10000,
										},
									})
								}
							/>
						}
					/>
					<AnimatePresence>
						{address && (
							<motion.p variants={fadeInVariants} className="home__form--balance">
								{selectedToken === TOKEN_ID ? TOKEN_ID : lklandTypeId} Balance: <span>{balance}</span>
							</motion.p>
						)}
					</AnimatePresence>
					<AnimatePresence>
						{selectedToken === TOKEN_ID && (
							<motion.span className="text-sm text-purple" {...motionContainerProps} variants={fadeInVariants}>
								Stake at least 300 land in order to use referral code and get bonus 2% APR. <br /> It can only be used
								once and cannot be yourself.
							</motion.span>
						)}
					</AnimatePresence>
					<AnimatePresence>
						{selectedToken === TOKEN_ID && (
							<Input
								placeholder="TOTHEMOON"
								label="Referral (herotag or address)"
								value={referralCode}
								onChange={handleChangeReferralCode}
							/>
						)}
					</AnimatePresence>
					<AnimatePresence>
						{selectedToken !== TOKEN_ID && (
							<CheckboxGroup label="Tokens" options={lklandOptions} onChange={handleSelectLkLand} single />
						)}
					</AnimatePresence>
					<motion.div variants={fadeInVariants} className="home__form--info">
						<Icon name="info" primary />
						{balance > 0 ? (
							<span>
								There will be a 5 days unbonding time and 30% penalty on rewards for withdrawing before the chosen
								timestamp ends.
							</span>
						) : (
							<a href="https://presale.landboard.io">
								<span>No {selectedToken}, no problem, buy some in the presale here.</span>
							</a>
						)}
					</motion.div>
					{!isMobile && (
						<Button
							className="filled"
							onClick={handleStake}
							disabled={disabled}
							hideComingSoon={selectedToken === TOKEN_ID}
							animate>
							{timeLeft > 0 ? "Stake in " + d + " : " + hours + " : " + minutes + " : " + seconds : "STAKE"}
						</Button>
					)}
				</motion.div>
			</div>

			<motion.div className="home__title">
				<motion.h1 variants={fadeInVariants}>
					CHOOSE YOUR <span className="text-purple">PLAN</span>
				</motion.h1>
				<motion.p variants={fadeInVariants}>Become a marketing guru through land advertisement</motion.p>
				<motion.span className="mb-2 text-sm" variants={fadeInVariants}>
					Check APRs for each plan{" "}
					<a className="underline text-purple" href="https://twitter.com/landboard_io/status/1505272042114924547">
						here.
					</a>
				</motion.span>
				<div className="plan-grid">
					<motion.div
						className="plan-grid__content"
						drag={isMobile ? "x" : false}
						dragConstraints={{ left: -800, right: 20 }}>
						<AnimatePresence>
							{plans.map((plan: any) => (
								<PlanCard
									{...plan}
									key={plan.title}
									isActive={activeDay === plan.days}
									Icon={<Icon name={plan.title.toLowerCase()} primary />}
									handleSelect={() => setActiveDay(plan.days)}
								/>
							))}
						</AnimatePresence>
					</motion.div>
				</div>
				{isMobile && (
					<Button
						className="filled"
						containerClassname="mobile-size"
						onClick={handleStake}
						disabled={disabled}
						hideComingSoon={selectedToken === TOKEN_ID}
						animate>
						{timeLeft > 0 ? "Stake in " + d + " : " + hours + " : " + minutes + " : " + seconds : "STAKE"}
					</Button>
				)}
			</motion.div>
		</motion.div>
	);
};

export default Home;
