import { refreshAccount, sendTransactions } from "@elrondnetwork/dapp-core";
import {
	Account,
	Address,
	AddressValue,
	ArgSerializer,
	BigUIntValue,
	BytesValue,
	ContractFunction,
	Egld,
	GasLimit,
	I8Value,
	Interaction,
	OptionValue,
	ProxyProvider,
	SmartContract,
	Transaction,
	TransactionPayload,
	TypedValue,
	U32Value,
	U8Value,
} from "@elrondnetwork/erdjs/out";
import { CONTRACT_ADDRESS, STAKE } from "config";
import { notifyTransaction } from "./transaction";

export default class StakeContract {
	contract: SmartContract;
	stakerAddress: Address;
	stakerAccount: Account;
	provider: ProxyProvider;

	constructor(account: any, contract: SmartContract, provider: ProxyProvider) {
		this.stakerAddress = account.address;
		this.stakerAccount = account;
		this.contract = contract;
		this.provider = provider;
	}

	createStakeTransaction = async (
		tokenId: string,
		stakeTypeId: number = 1,
		landAmount: number = 100,
		referralAddress?: string
	) => {
		const args: TypedValue[] = [
			BytesValue.fromUTF8(tokenId),
			new BigUIntValue(Egld(landAmount).valueOf()),
			BytesValue.fromUTF8(STAKE),
			new U32Value(stakeTypeId),
		];

		if (referralAddress) args.push(new AddressValue(new Address(referralAddress)));

		let { argumentsString } = new ArgSerializer().valuesToString(args);
		argumentsString = argumentsString.toUpperCase();
		const data = new TransactionPayload(`ESDTTransfer@${argumentsString}`);

		let tx = new Transaction({
			receiver: new Address(CONTRACT_ADDRESS[tokenId]),
			gasLimit: new GasLimit(10000000),
			data: data,
		});
		tx.setNonce(this.stakerAccount.nonce);
		await refreshAccount();
		sendTransactions({
			transactions: tx,
		});
		notifyTransaction({ ...tx, status: "pending" });
	};

	createUnstakeTransaction = async (nodeId: number) => {
		let tx = this.contract.call({
			func: new ContractFunction("unstake"),
			gasLimit: new GasLimit(6000000),
			args: [new I8Value(nodeId)],
		});
		await refreshAccount();
		sendTransactions({
			transactions: tx,
		});
		notifyTransaction({ ...tx, status: "pending" });
	};

	createClaimTransaction = async (nodeId: number) => {
		let tx = this.contract.call({
			func: new ContractFunction("claim"),
			gasLimit: new GasLimit(50000000),
			args: [new U32Value(nodeId)],
		});
		await refreshAccount();
		sendTransactions({
			transactions: tx,
		});
		notifyTransaction({ ...tx, status: "pending" });
	};

	getStakeTypes = async () => {
		const interaction: Interaction = this.contract.methods.getStakeTypes();
		const queryResponse = await this.contract.runQuery(this.provider, interaction.buildQuery());
		const res = interaction.interpretQueryResponse(queryResponse);
		if (!res || !res.returnCode.isSuccess()) return;
		const value = res.firstValue.valueOf();

		return value;
	};

	getStakerAddresses = async () => {
		const interaction: Interaction = this.contract.methods.getStakerAddresses();
		const queryResponse = await this.contract.runQuery(this.provider, interaction.buildQuery());
		const res = interaction.interpretQueryResponse(queryResponse);
		if (!res || !res.returnCode.isSuccess()) return;
		const value = res.firstValue.valueOf();

		return value;
	};

	getNodesPerStaker = async () => {
		const args = [new AddressValue(new Address(this.stakerAddress))];
		const interaction: Interaction = this.contract.methods.getNodesPerStaker(args);
		const queryResponse = await this.contract.runQuery(this.provider, interaction.buildQuery());

		const res = interaction.interpretQueryResponse(queryResponse);
		if (!res || !res.returnCode.isSuccess()) return;

		return res.firstValue.valueOf();
	};

	getReferredCount = async () => {
		const args = [new AddressValue(new Address(this.stakerAddress))];
		const interaction: Interaction = this.contract.methods.getReferredCount(args);
		const queryResponse = await this.contract.runQuery(this.provider, interaction.buildQuery());
		const res = interaction.interpretQueryResponse(queryResponse);
		if (!res || !res.returnCode.isSuccess()) return;
		const value = res.firstValue.valueOf();
		return value;
	};

	getApyOfStaker = async () => {
		const args = [new AddressValue(new Address(this.stakerAddress))];
		const interaction: Interaction = this.contract.methods.getApyOfStaker(args);
		const queryResponse = await this.contract.runQuery(this.provider, interaction.buildQuery());
		const res = interaction.interpretQueryResponse(queryResponse);
		if (!res || !res.returnCode.isSuccess()) return;
		const value = res.firstValue.valueOf();
		return value;
	};
}
