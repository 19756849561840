import { useGetAccountInfo, useGetLoginInfo } from "@elrondnetwork/dapp-core";
import { fadeInVariants, motionContainerProps } from "animation/variants";
import Button from "components/buttons";
import StakeCard from "components/cards/StakeCard";
import Dracula from "components/icons/Dracula";
import { Icon } from "components/icons/Icon";
import ClaimModal from "components/modals/ClaimModal";
import { landPlans, LKTOKEN_ID1, LKTOKEN_ID2, TOKEN_ID } from "config";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useStakeContract from "utils/useStakeContract";
import { operations } from "@elrondnetwork/dapp-utils";
import { toFixed } from "utils";

const placeholderNodes = [
	{
		stakeTypeId: 1,
		stakedAmount: 1000 + " LAND",
		// in milliseconds
		stakeTimestamp: 1648187278000,
		lockingTimestamp: 1123200000,
		unlockTimestamp: 1649310478000,
		// unstakeTimestamp: null,
		// delegationTimestamp: null,
		claimableAmount: 1100 + " LAND",
		claimable: false,
		unstaked: false,
		// replace 1 with stakeTypeId
		...landPlans.filter((plan) => plan.id === 1)[0],
		id: 1,
		Icon: <Icon name={landPlans.filter((plan) => plan.id === 1)[0].title.toLowerCase()} />,
	},
	{
		stakeTypeId: 2,
		stakedAmount: 1000 + " LAND",
		// in milliseconds
		stakeTimestamp: 1647758207000,
		lockingTimestamp: 172800000,
		unlockTimestamp: 1647931007000,
		unstakeTimestamp: 1647931007000,
		delegationTimestamp: 172800000,
		claimableAmount: 1100 + " LAND",
		claimable: false,
		unstaked: false,
		// replace 1 with stakeTypeId
		...landPlans.filter((plan) => plan.id === 2)[0],
		id: 2,
		Icon: <Icon name={landPlans.filter((plan) => plan.id === 1)[0].title.toLowerCase()} />,
	},
	{
		stakeTypeId: 2,
		stakedAmount: 1000 + " LAND",
		// in milliseconds
		stakeTimestamp: 1647758207000,
		lockingTimestamp: 172800000,
		unlockTimestamp: 1647931007000,
		unstakeTimestamp: 1647931007000,
		delegationTimestamp: 172800000,
		claimableAmount: 1100 + " LAND",
		claimable: true,
		unstaked: true,
		// replace 1 with stakeTypeId
		...landPlans.filter((plan) => plan.id === 2)[0],
		id: 3,
		Icon: <Icon name={landPlans.filter((plan) => plan.id === 1)[0].title.toLowerCase()} />,
	},
];

const Staked = () => {
	const { isLoggedIn } = useGetLoginInfo();

	const { stakeContract } = useStakeContract(TOKEN_ID);
	const { stakeContract: stakeContractLk1 } = useStakeContract(LKTOKEN_ID1);
	const { stakeContract: stakeContractLk2 } = useStakeContract(LKTOKEN_ID2);

	const { address } = useGetAccountInfo();
	const navigate = useNavigate();

	const [stakedNodes, setStakedNodes] = useState<any>([]);
	const [stakedNodesLk1, setStakedNodesLk1] = useState<any>([]);
	const [stakedNodesLk2, setStakedNodesLk2] = useState<any>([]);
	const [selectedNode, setSelectedNode] = useState<any>(null);
	const [showModal, setShowModal] = useState(false);

	const mapStakedNodes = (nodes: any, tokenId: string) => {
		if (!nodes) return [];
		return nodes.map((node: any, index: number) => {
			const stakeTypeId = node.stake_type_id.valueOf();
			const flag = node.state.valueOf(); // 1 not unstakeable 2 for claimable

			const stakedAmount = operations.denominate({
				input: toFixed(node.stake_amount.valueOf()),
				denomination: 18,
				showLastNonZeroDecimal: false,
				decimals: 4,
			});
			const rewardAmount = operations.denominate({
				input: toFixed(node.reward_amount.valueOf()),
				denomination: 18,
				showLastNonZeroDecimal: false,
				decimals: 4,
			});
			const rewardAmountWT = operations.denominate({
				input: (parseFloat(rewardAmount) + parseFloat(rewardAmount) * 0.42) * 10 ** 18 + "",
				denomination: 18,
				showLastNonZeroDecimal: false,
				decimals: 4,
			});

			const stakeTimestamp = node.stake_timestamp.valueOf();
			const delegationTimestamp = node.delegation_timestamp.valueOf();
			const lockingTimestamp = node.locking_timestamp.valueOf();
			const unstakeTimestamp = node.unstake_timestamp.valueOf();
			const landPlan = landPlans.filter((plan) => plan.id == stakeTypeId)[0];
			const id = node.node_id;

			return {
				...landPlan,
				id,
				stakedAmount: stakedAmount + " " + tokenId.split("-")[0],
				tokenId,
				stakeTypeId,
				stakeTimestamp,
				rewardAmount: rewardAmountWT,
				claimableAmount: parseInt(stakedAmount) + parseInt(rewardAmountWT) + " LAND",
				lockingTimestamp,
				claimable: flag == 2,
				unstaked: flag == 3,
				unstakeTimestamp,
				delegationTimestamp,
				Icon: <Icon name={landPlan.title.toLowerCase()} />,
			};
		});
	};

	useEffect(() => {
		if (!isLoggedIn) {
			navigate("/");
		}
	}, [isLoggedIn]);

	useEffect(() => {
		if (address && stakeContract) {
			stakeContract
				.getNodesPerStaker()
				.then((nodes) => setStakedNodes(mapStakedNodes(nodes, TOKEN_ID)))
				.catch((err) => console.log(err));
			stakeContractLk1
				?.getNodesPerStaker()
				.then((nodes) => setStakedNodesLk1(mapStakedNodes(nodes, LKTOKEN_ID1)))
				.catch((err) => console.log(err));
			stakeContractLk2
				?.getNodesPerStaker()
				.then((nodes) => setStakedNodesLk2(mapStakedNodes(nodes, LKTOKEN_ID2)))
				.catch((err) => console.log(err));
		}
	}, [address, stakeContract, stakeContractLk1, stakeContractLk2]);

	if (!isLoggedIn) return null;

	const allStakedNodes = [...stakedNodes, ...stakedNodesLk1, ...stakedNodesLk2];

	return (
		<motion.div className="staked" {...motionContainerProps}>
			<motion.div className="home__title">
				<motion.h1 variants={fadeInVariants}>
					YOUR STAKED <span className="text-purple">LAND</span>
				</motion.h1>
				<motion.p variants={fadeInVariants}>Stake your way to fortune in the marketing metaverse.</motion.p>
			</motion.div>
			<div className={stakedNodes.length > 0 ? "staked-grid" : ""}>
				{stakedNodes.length == 0 && (
					<div className="not-found">
						<Dracula />
						<motion.p className="text-center" variants={fadeInVariants}>
							You don't have any staked LAND.
						</motion.p>
						<div className="max-w-[20rem]">
							<Button className="filled" link="/">
								Stake here
							</Button>
						</div>
					</div>
				)}
				<AnimatePresence>
					{allStakedNodes?.length > 0 &&
						allStakedNodes.map((node: any, index: number) => {
							return (
								<StakeCard
									key={node.id}
									{...node}
									handleClaim={() => {
										setSelectedNode(node);
										setShowModal(true);
									}}
								/>
							);
						})}
				</AnimatePresence>
			</div>
			<AnimatePresence>
				{showModal && (
					<ClaimModal
						{...selectedNode}
						title="Lander"
						onClose={() => {
							setShowModal(false);
							setSelectedNode(null);
						}}
					/>
				)}
			</AnimatePresence>
		</motion.div>
	);
};

export default Staked;
