export const dAppName = "LandBoard Staking";

const convertToHex = (str: string) => {
	var hex, i;

	var result = "";
	for (i = 0; i < str.length; i++) {
		hex = str.charCodeAt(i).toString(16);
		result += ("000" + hex).slice(-4);
	}

	return result;
};

export const TOKEN_ID = process.env.NODE_ENV === "production" ? "LAND-40f26f" : "SVEN-0deee5";
export const LKTOKEN_ID1 = "LKLAND-6cf78e";
export const LKTOKEN_ID2 = "LKLAND-c617f7";

export const TOKEN_ID_ONLY_HEX = convertToHex(TOKEN_ID);
export const TOKEN_ID_HEX = "0x" + TOKEN_ID_ONLY_HEX;
export const REFERRRAL_ACTIVATION_AMOUNT = 100000000000000000000;
export const APY_INCREASE_PER_REFERRAL = 50;
export const MAX_APY_INCREASE_BY_REFERRAL = 1000;

export const STAKE = "stake";
export const STAKE_ONLY_HEX = convertToHex(STAKE);

export const CONTRACT_ADDRESS = {
	[TOKEN_ID]:
		process.env.NODE_ENV === "production"
			? "erd1qqqqqqqqqqqqqpgqggd9ep2kvwkmtl6u4ysz62wv0kt0z6kjjpmqtv8a9t"
			: "erd1qqqqqqqqqqqqqpgqnz9ctnn9776xa7ua6u6s0tfz2zvn39ehjpmqr0kp9h",
	[LKTOKEN_ID1]:
		process.env.NODE_ENV === "production"
			? "erd1qqqqqqqqqqqqqpgqg2d0lwguqx9cmkyyvfspwd9lyhqfcxxyjpmqhhule6"
			: "erd1qqqqqqqqqqqqqpgqsf9l0fh976trl7mglklsd252jngg3vm5jpmqtxxfft",
	[LKTOKEN_ID2]:
		process.env.NODE_ENV === "production"
			? "erd1qqqqqqqqqqqqqpgqgyc05rxl8jq89dwumxk5cuyvrcdtujn7jpmqlrddnk"
			: "erd1qqqqqqqqqqqqqpgqnz9ctnn9776xa7ua6u6s0tfz2zvn39ehjpmqr0kp9h",
};

export const CONTRACT_ABI_URL = "/assets/abi/landboard-node-stake.abi.json";
export const CONTRACT_NAME = "LandboardStaking";
export const TIMEOUT = 6000;
export const LAUNCH_TIME = 1648278000000;

export const landPlans = [
	{
		id: 1,
		title: "Lander",
		days: 15,
		apr: 50,
	},
	{
		id: 2,
		title: "Fan",
		days: 30,
		apr: 52,
	},
	{
		id: 3,
		title: "LandLord",
		days: 60,
		apr: 55,
	},
	{
		id: 4,
		title: "Wizard",
		days: 120,
		apr: 58,
	},
	{
		id: 5,
		title: "Cyborg",
		days: 240,
		apr: 64,
	},
];

export const lockedLandPlans = [
	{
		id: 2,
		title: "Fan",
		days: 30,
		apr: 52,
	},
	{
		id: 3,
		title: "LandLord",
		days: 60,
		apr: 55,
	},
	{
		id: 4,
		title: "Wizard",
		days: 120,
		apr: 58,
	},
	{
		id: 5,
		title: "Cyborg",
		days: 240,
		apr: 64,
	},
];
