import Button from "components/buttons";
import toast from "react-hot-toast";
import { motion } from "framer-motion/dist/framer-motion";
import { scaleFadeInVariants } from "animation/variants";
import { useGetAccountInfo, DappUI, useGetNetworkConfig } from "@elrondnetwork/dapp-core";
import Spinner from "components/loading/Spinner";
import useTransactions from "utils/useTransactions";
import { CONTRACT_ADDRESS, TOKEN_ID } from "config";
interface TransactionNotificationProps {
	id: string;
	visible: boolean;
	message: string;
	txHash?: string;
	hash?: string;
	txValue: string;
	status: "fail" | "success" | "pending" | "notExecuted";
}

const TransactionNotification = ({
	message,
	id,
	visible,
	txHash,
	hash,
	txValue,
	status,
}: TransactionNotificationProps) => {
	const { network } = useGetNetworkConfig();
	const { markTransactionViewed } = useTransactions(CONTRACT_ADDRESS[TOKEN_ID]);

	const color = status === "success" ? "green" : status === "fail" ? "red" : "purple";
	const thash = txHash ?? hash;

	return (
		<motion.div
			variants={scaleFadeInVariants}
			animate={visible ? "visible" : "hidden"}
			className={`lboard-notification`}>
			<div className="relative flex-1 w-0 p-4">
				<span className="lboard-notification__value">
					<DappUI.Denominate value={txValue} decimals={2} />
				</span>
				<p className="text-sm font-bold mb-2">{message}</p>
				<p className={`text-sm font-bold text-${color}`}>
					{thash && (
						<a
							href={`${network.explorerAddress}/transactions/${thash}`}
							{...{
								target: "_blank",
							}}
							className="underline"
							title="View in Explorer">
							{thash.substring(0, 10)}...
							{thash.substring(thash.length - 10, thash.length)}
						</a>
					)}
				</p>
			</div>
			{status === "pending" && (
				<div className={`flex items-center justify-center`}>
					<Spinner />
				</div>
			)}
			<div className={`flex border-l-2 border-purple items-center justify-center`}>
				<Button
					className="text"
					onClick={() => {
						toast.dismiss(id);
						thash && markTransactionViewed(thash);
					}}>
					Close
				</Button>
			</div>
		</motion.div>
	);
};

export default TransactionNotification;
